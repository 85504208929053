// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
     // appVersion: require('../../package.json').version + '-qa',
     //  appVersion: require('../../package.json').version + '-dev',
  //  appVersion: require('../../package.json').version + '-uat',
   appVersion: require('../../package.json').version,
  production: false,
       // apiUrl: 'https://jfl-api-dev.roadcast.co.in/api/v1/',
      apiUrl: 'https://jfl-api.roadcast.co.in/api/v1/',
      //   apiUrl: 'https://jfl-api-qa.roadcast.co.in/api/v1/',
  //  apiUrl: 'https://jfl-api-uat.roadcast.co.in/api/v1/',

    hereApiKey: '3Xz_d2T_zyHKm1WhBNRAdtmToAQ_HhLmWtMstKeFo34',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
