import {ChangeDetectorRef, Component } from '@angular/core';
import {OrderService} from "../order.service";
import {Order} from "../order";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import * as moment from "moment";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";


@Component({
  selector: 'app-customer-tracking-page',
  templateUrl: './customer-tracking-page.component.html',
  styleUrls: ['./customer-tracking-page.component.css']
})
export class CustomerTrackingPageComponent {

  order: Order = {
    id: '',
    rider: {
      name: ''
    },
    delivery_address: {
      google_address: ''
    }} as Order;
  feedbackorder = '';
  feedbackStatus: any;
  rating: any = {
  };
  rating3 = 0;
  public form: FormGroup;
  sub: Subscription | any;
  order_status: any;
  orderStatusDist = {
    50: null,
    200: null,
    300: null,
    400: null,
    500: null,
    600: null,
  } as any;
  orderStatusDate = {
    50: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '',
  } as any;
  feedbackPolling = false;
  notes = 'Delivery in';
  orderSummaryValue: boolean = false;
  order_Payment: any;
  body_temp: any;
  firstLocationTime: any;
  updatedTime: any = 0;
  currentUpdateTime: any;
  subTitleTime: any;
  riderNumber: any;
  infoModelValue: boolean = false;
  currentApplicationVersion = environment?.appVersion;
  currencyCode: any;
  resData: any = {};
  time: any;
  subTime: Subscription | any;

  isFirstUpdate = true;
  nextPollETA = moment();

  constructor(public orderService: OrderService,
              private router: Router,
              private fb: FormBuilder,
              private http: HttpClient,
              private cd: ChangeDetectorRef) {
    this.form = this.fb.group({
      rating1: ['', Validators.required],
      rating2: [4]
    });
    this.orderService.updatesSubject.subscribe(pollingResponse => {
      this.receivedUpdate();
    });
    if (this.orderService.pollingResponse?.data?.id) {
      this.receivedUpdate();
    }
  }



  receivedUpdate() {
    if (!this.orderService.pollingResponse?.data) {
      return;
    }
    this.order = this.orderService.pollingResponse.data;
    this.rating = this.orderService.pollingResponse.rating;
    this.order_status = this.orderService.pollingResponse.order_status;
    this.order_Payment = this.orderService.pollingResponse.order_payment[0];
    this.order_status.forEach((row: any) => {
      this.orderStatusDist[row.status_code] = row.status_code as any;
      this.orderStatusDate[row.status_code] = row.created_on as any;
    })
    this.body_temp = this.orderService.pollingResponse.rider_avail?.body_temp_vaccination_status?.EmployeeBodyTemp;

    if(this.order.status_name === 'delivered' || this.order.status_name === 'cancelled'
        ||  this.order.status_name === 'reached_store' || this.order.order_undeliver){
      console.log('call unsubscribe after');
      this.subTime.unsubscribe();
      this.sub.unsubscribe();
    } else if (moment().isAfter(this.nextPollETA)) {
      console.log('################################################## getTimeBtwTwoLatLng')
      this.getTimeBtwTwoLatLng(this.order);
    }

    if (this.isFirstUpdate) {
      this.isFirstUpdate = false;
      this.numberMasking();
      this.fetchCurrency().then();
    }

  }

  async fetchCurrency() {
    const company = await fetch(`${environment.apiUrl}order/get_redis_company/${this.orderService.orderId}` , {
      method: "GET",
    });
    const companyData = await company.json();
    this.currencyCode = companyData.data.currencyCode;
  }


  getTimeBtwTwoLatLng(order: any){
    const lat1 = order.rider_position.latitude;
    const lng1 = order.rider_position.longitude;
    const lat2 = order.delivery_location.latitude;
    const lng2 = order.delivery_location.longitude;
    try {
      if (lat1 && lat2)
      this.http.get('https://route.ls.hereapi.com/routing/7.2/calculateroute.json', {
          params: {
            apikey: environment.hereApiKey,
            waypoint0: `geo!${lat1},${lng1}`,
            waypoint1: `geo!${lat2},${lng2}`,
            mode: 'fastest;car;'
          }
        }).subscribe(res =>{
          this.resData = res;
          this.time = this.resData.response.route[0].summary?.travelTime;
          this.updatedTime = this.time ? Number(this.time/60) : this.order.drop_off_eta/60;
          this.subTitleTime = (this.updatedTime).toFixed(0) + ' min';
          this.firstLocationTime = this.order.drop_off_eta/60;
          const firstPerValue = 100 / this.firstLocationTime;
          this.currentUpdateTime = Math.abs(this.firstLocationTime - this.updatedTime)* firstPerValue;
          this.cd.detectChanges();
      })
    } catch (e) {
      console.log(e);
    }
    this.updatedTime = this.time ? Number(this.time/60) : this.order.drop_off_eta/60;
    this.subTitleTime = (this.updatedTime).toFixed(0) + ' min';
    this.firstLocationTime = this.order.drop_off_eta/60;
    const firstPerValue = 100/ this.firstLocationTime;
    this.currentUpdateTime = Math.abs(this.firstLocationTime - this.updatedTime)* firstPerValue;
    this.nextPollETA = moment().add(30, 'second');
  }




  feedback() {
    const api_url = environment.apiUrl;
    fetch(api_url + 'order/order_feedback/' + `${this.order.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        stars: this.rating3,
        feedback: this.feedbackorder,
        customer_id:this.order.customer_id,
        rider_id: this.order.rider_id,
      })
    }).then(async( res) => {
     const resData =  await res.json();
      this.feedbackStatus = resData.status;
      await this.orderService.fetchOrder();
      this.order = this.orderService.order;
      this.rating = this.orderService.rating;
    })
  }
  numberMasking(){
    const date = this.order.created_on;
    console.log('date ', moment(date).format('YYYY-MM-DD'));
    const api_url = environment.apiUrl;
    fetch(api_url + 'order/virtual_number/virtualnumber', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        order_number: this.order.external_id,
        order_date: moment(date).format('YYYY-MM-DD'),
        location_code: "DPI66683",
        employee_code: this.order.rider_id,
      })
    }).then(async( res) => {
       const riderData  =  await res.json();
       this.riderNumber = riderData.virtualNumber;
    });
  }

  orderSummary() {
    this.orderSummaryValue = true;
  }

  orderSummaryValueClose() {
    this.orderSummaryValue = false;
  }

  getRating(event: any, value: any) {
    this.rating3 = value;
  }

  riderPan() {
    this.orderService.riderPosition.next({lat:this.order.rider_position.latitude,
      lng: this.order.rider_position.longitude});
  }

  infoModel() {
    this.infoModelValue = true;
  }

  infoModelClose() {
    this.infoModelValue = false;
  }
}
export interface HereApiResponse {
  routes: '';
}
