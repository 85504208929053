import {Component, ViewChild} from '@angular/core';
import {OrderService} from "../order.service";
import {Geom, Order} from "../order";
import {HttpClient} from "@angular/common/http";
import {environment} from '../../environments/environment';
import * as HereFlexible from '../here-flexible-polyline';
import Polyline = google.maps.Polyline;
import LatLngLiteral = google.maps.LatLngLiteral;
import {getRiderIconBike} from '../riderIcon';
import * as moment from 'moment';

@Component({
    selector: 'app-test-map',
    templateUrl: './test-map.component.html',
    styleUrls: ['./test-map.component.css']
})
export class TestMapComponent {
    @ViewChild('map', {static: false}) mapElement: any;

    map: any;
    proposedRiderPathCoordinates: LatLngLiteral[] = [];
    order: Order = {} as Order;
    pathPolyLine = new Polyline();
    private usedProposedPathTill = 0;
    private riderPolyLine: google.maps.Polyline | undefined;


    bigSteps: LatLngLiteral[] = [] as LatLngLiteral[];

    lastMoved: number = 0;
    // nextPolling: number = + new Date();

    currentRiderPositionDrawnOnMap: google.maps.LatLng | any;

    constructor(public orderService: OrderService, private http: HttpClient) {
        this.orderService.updatesSubject.subscribe(pollingResponse => {
            this.receivedUpdate();
            if (!this.map) {
                this.mapReady().then();
            }
        });
        if (this.orderService.pollingResponse?.data?.id) {
            this.receivedUpdate();
            if (!this.map) {
                this.mapReady().then();
            }
        }
        this.moveRider();
    }

    async mapReady() {
        this.pathPolyLine = new Polyline();
        this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
            center: {
                lat: this.order.delivery_location.latitude,
                lng: this.order.delivery_location.longitude,
            },
            zoom: 14,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            scrollwheel: true,
            mapTypeControl: false,
            scaleControl: false,
            draggable: true,
            disableDoubleClickZoom: true,
            zoomControl: false,
            maxZoom: 16,
            gestureHandling: 'greedy',
            styles: [
                {
                    "featureType": "poi.attraction",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.government",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.medical",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "poi.place_of_worship",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.school",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.sports_complex",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "stylers": [
                        {
                            "saturation": 100
                        },
                        {
                            "lightness": 100
                        },
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ]
        });

        const pickupLatLng = {
            lat: this.order.pick_up_location.latitude,
            lng: this.order.pick_up_location.longitude,
        };
        const pickupMarker = new google.maps.Marker({
            position: new google.maps.LatLng(pickupLatLng.lat, pickupLatLng.lng
            ),
            icon: {
                url: 'assets/images/store.svg',
                scaledSize: new google.maps.Size(48, 48), // size
            },
            title: '',
        });
        pickupMarker.setMap(this.map);
        google.maps.event.addListener(pickupMarker, 'click', () => {
            const infoWindow = new google.maps.InfoWindow({
                content: "Domino's store"
            });
            infoWindow.open(this.map, pickupMarker);
        });
        const dropLatLng = {
            lat: this.order.delivery_location.latitude,
            lng: this.order.delivery_location.longitude,
        };
        const dropMarker = new google.maps.Marker({
            position: new google.maps.LatLng(dropLatLng.lat, dropLatLng.lng),
            icon: {
                url: 'assets/images/home.svg',
                scaledSize: new google.maps.Size(48, 48), // size
            },
            title: ''
        });
        dropMarker.setMap(this.map);
        google.maps.event.addListener(dropMarker, 'click', () => {
            const infoWindow = new google.maps.InfoWindow({
                content: 'Drop'
            });
            infoWindow.open(this.map, dropMarker);
        });

        const bounds = new google.maps.LatLngBounds(new google.maps.LatLng(pickupLatLng.lat, pickupLatLng.lng));
        bounds.extend(new google.maps.LatLng(dropLatLng.lat, dropLatLng.lng));
        this.map.fitBounds(bounds);
        this.map.setZoom(15);
        await this.getRiderPathFromHerePathThenCacheLocally();
    }

    receivedUpdate() {
        // this.nextPolling = + moment().add(400, 'ms').toDate();
        if (this.orderService.pollingResponse)
            this.order = this.orderService.pollingResponse?.data;
        const geom = this.orderService.pollingResponse?.rider_avail?.rider_position;
        if (this.proposedRiderPathCoordinates?.length && geom?.latitude) {
            let lowestDistanceBGeom = 10000;
            let lowestIndexBGeom = 0;

            // find straight line distance between new coord and coordinate on plotted path, if rider is on same path, take those coords; else fetch new path from here maps
            this.proposedRiderPathCoordinates.forEach((pathCord, index) => {
                const diff = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng({
                        lat: geom.latitude,
                        lng: geom.longitude,
                    }),
                    new google.maps.LatLng(pathCord.lat, pathCord.lng));
                if (diff <= lowestDistanceBGeom) {
                    lowestDistanceBGeom = diff;
                    lowestIndexBGeom = index;
                }
            });

            if (lowestDistanceBGeom < 70) {
                // rider is on plotted path

                // if found nearest lat lng is in past of rider movement (rider reverse icon glitch)
                if (lowestIndexBGeom <= this.usedProposedPathTill && this.proposedRiderPathCoordinates.length > (lowestIndexBGeom + 2)) {
                    lowestIndexBGeom += 1;
                }
                this.usedProposedPathTill = lowestIndexBGeom;
                this.bigSteps.push(this.proposedRiderPathCoordinates[lowestIndexBGeom]);

            } else {
                this.bigSteps.push({
                    lat: geom.latitude,
                    lng: geom.longitude,
                });
                this.getRiderPathFromHerePathThenCacheLocally().then();
            }
            this.map.panTo(this.bigSteps[this.bigSteps.length-1]);
        }
    }


    async getRiderPathFromHerePathThenCacheLocally() {
        const order = this.orderService.pollingResponse?.data;
        if (!order) {
            return;
        }
        let origin: Geom = {
            latitude: order.pick_up_location.latitude,
            longitude: order.pick_up_location.longitude,
        };
        if (this.orderService.pollingResponse?.rider_avail?.rider_position?.latitude) {
            origin = {
                latitude: this.orderService.pollingResponse.rider_avail.rider_position.latitude,
                longitude: this.orderService.pollingResponse.rider_avail.rider_position.longitude,
            };
        }
        const destination = {
            latitude: order.delivery_location?.latitude,
            longitude: order.delivery_location?.longitude
        };
        if (!origin?.latitude || !destination?.latitude) {
            return;
        }
        this.proposedRiderPathCoordinates = await this.fetchRouteFromHereMaps(origin, destination);
        try {
            this.pathPolyLine.setMap(null);
        } catch (e) {
            console.log(e);
        }

        this.pathPolyLine = new google.maps.Polyline({
            strokeColor: '#0047b3',
            map: this.map,
            path: this.proposedRiderPathCoordinates, geodesic: true, visible: true,
        } as google.maps.PolylineOptions);


        if (!this.riderPolyLine) {
            let riderPath = [
                {lat: order.pick_up_location.latitude, lng: order.pick_up_location.longitude},
                {lat: origin.latitude, lng: origin.longitude}
            ];
            this.riderPolyLine = new google.maps.Polyline({
                strokeColor: '#0078AC',
                strokeOpacity: 0,
                map: this.map,
                path: riderPath,
                icons: getRiderIconBike(0),
                zIndex: 9999999999999,
            });
            this.currentRiderPositionDrawnOnMap = new google.maps.LatLng({lat: origin.latitude, lng: origin.longitude});
        }

    }


    async fetchRouteFromHereMaps(a: Geom, b: Geom): Promise<LatLngLiteral[]> {
        try {
            const response: HereResponse = (await this.http.get(
                'https://router.hereapi.com/v8/routes', {
                    params: {
                        origin: `${a.latitude},${a.longitude};matchSideOfStreet=onlyIfDivided`,
                        transportMode: 'car',
                        destination: `${b.latitude},${b.longitude}`,
                        'return': 'polyline',
                        apikey: environment.hereApiKey
                    },
                }).toPromise()) as HereResponse;
            const latLngList = HereFlexible.decode(response.routes[0].sections[0].polyline).polyline;
            return latLngList.map((x: any) => {
                return {lat: x[0], lng: x[1]}
            })
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    private moveRider() {

        requestAnimationFrame(() => {
            this.moveRider();
        })
        if (!this.riderPolyLine || !this.bigSteps.length) {
            return;
        }

        // controlling speed here
        if (+ new Date() < (this.lastMoved + 2)) {
            return;
        }

        let newPosition = new google.maps.LatLng(this.bigSteps[0]);

        if (newPosition.lat() == this.currentRiderPositionDrawnOnMap?.lat() && newPosition.lng() == this.currentRiderPositionDrawnOnMap.lng()
            || google.maps.geometry.spherical.computeDistanceBetween(this.currentRiderPositionDrawnOnMap, newPosition) < 10) {
            this.bigSteps.shift();
            if (!this.bigSteps.length) {
                return;
            }
            newPosition = new google.maps.LatLng(this.bigSteps[0])
        }

        let riderSpeed = 0.02;
        // 0.01 fast
        // 0.001 slow

        const toPosition = this.bigSteps.length < 2 ? newPosition : new google.maps.LatLng(this.bigSteps[this.bigSteps.length - 1]);
        const distance = google.maps.geometry.spherical.computeDistanceBetween(this.currentRiderPositionDrawnOnMap, toPosition);
        if (distance) {
            riderSpeed = (distance / 10000) + 0.0009
        }
        if (riderSpeed > 0.9) {
            riderSpeed = 0.9;
        }
        newPosition = google.maps.geometry.spherical.interpolate(this.currentRiderPositionDrawnOnMap, newPosition, riderSpeed);

        this.riderPolyLine.getPath().push(new google.maps.LatLng(newPosition));
        this.currentRiderPositionDrawnOnMap = newPosition;
        this.lastMoved = + new Date();
    }

}

export interface HereResponse {
    routes: HereResponseRoute[];
}

export interface HereResponseRoute {
    id: string;
    sections: HereResponseRouteSection[],
}

export interface HereResponseRouteSection {
    id: string;
    type: string;
    departure: any;
    arrival: any;
    polyline: string;
    transport: {
        mode: string;
    };
}
