<!-- Header Start Here -->
<header>
  <div class="logo"><img src="assets/images/logo.jpg" alt=""></div>
</header>
<!-- Header End Here -->

<!-- Google Map Start Here -->
<section class="gmap">
  <div class="powered"><img src="assets/images/rc_ic.svg" alt="map"></div>
  <div class="recenter" (click)="riderPan()"><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 121.64 122.86"><title>direction-top</title>
      <path d="M59,1.24.2,120.08a1.92,1.92,0,0,0,.88,2.58,1.89,1.89,0,0,0,1.76,0h0l58-30.87,58,30.87h0a1.89,
      1.89,0,0,0,1.76,0,1.92,1.92,0,0,0,.88-2.58L62.64,1.24a2,2,0,0,0-3.64,0Z" fill="#0074ad"/></svg></div>
  <app-test-map></app-test-map>
</section>
<!-- Google Map End Here -->

<!-- Order Container Start Here -->
<section class="ordercont">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="row orderstatus">
          <div class="col-6"><h2>Order No</h2></div>
          <div class="col-6 txt-right"><h2>#{{order.external_id | uppercase}}</h2></div>
          <div class="w-100"></div>
          <div class="col-12">
            <div class="row">
              <div class="col-7">
                <ol class="timeline">
                  <li [ngClass]="orderStatusDist['400'] === 400 ? 'active' : ''"><img src="assets/images/tick-w.png">Start Bike<span>at {{orderStatusDate["400"] | date: 'hh:mm a'}}</span></li>
                  <li [ngClass]="orderStatusDist['500'] === 500 ? 'active' : ''"><img src="assets/images/tick-w.png">Reached Customer Parking<span>at {{orderStatusDate["500"] | date: 'hh:mm a'}}</span></li>
                  <li [ngClass]="orderStatusDist['600'] === 600 ? 'active' : ''"><img src="assets/images/tick-w.png">Delivered<span>at {{orderStatusDate["600"] | date: 'hh:mm a'}}</span></li>
                </ol>
              </div>
              <div class="col-5 txt-right">
                  <circle-progress
                          [percent]="currentUpdateTime"
                          [radius]="55"
                          [titleFontSize]="'12'"
                          [title]="notes"
                          [subtitle]="subTitleTime"
                          [maxPercent]="firstLocationTime"
                          [animationDuration]="100"
                  ></circle-progress>
            </div>
            </div>
          </div>

         <div class="col-12 rider" *ngIf="order.status_code === 400">
              Rider has picked up your order
          </div>
          <div class="col-12 rider"  *ngIf="order.status_code === 500">
              Rider has reached your gate
          </div>
          <div class="col-12 rider"  *ngIf="order.status_code === 600">
              Rider has delivered your order
          </div>
        </div>
      </div>
    </div>

    <div class="row user">
      <div class="col">
        <div class="row">
<!--          <div class="col-2 u-pic"><img src="assets/images/user.jpg" alt=""></div>-->
          <div class="col-8 u-name"><h6>{{order.rider.name}}</h6>
           </div>
          <div class="col-4 callbtn txtright">
            <img src="assets/images/info_ic.svg" alt="" (click)="infoModel()">
            <a href="tel: {{order.rider ? riderNumber : ''}}"
                                                 style="text-decoration: none">
              <img src="assets/images/call_ic.svg" alt="" class="ml-6"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="row bottombtn">
      <div class="col-12">
        <button type="button" class="btn btn-primary w-100" (click)="orderSummary()">VIEW ORDER INFO</button>
      </div>
<!--      <div class="col-12 issue">-->
<!--        Facing Issue? <a>Chat with us</a>-->
<!--      </div>-->
      <div class="col-12 poweredbot">
        Powered by RoadCast <span style="float: right">{{currentApplicationVersion}}</span>
      </div>
    </div>
    <!-- Scrollable modal -->
  </div>
</section>
<!-- Order Container End Here -->

<!-- Feedback Pop Up Start Here -->
<!--<div *ngIf="order.status_name === 'delivered' || order.status_name === 'reached_store'">-->
<!--    <div class="feedback-popup" *ngIf="!rating?.feedback">-->
<!--    <div class="s-message">-->
<!--      <img src="assets/images/successful.gif" alt="">-->
<!--      <div class="txts"><p class="success-text">Order Successfully Delivered</p>-->
<!--        <p class="order-id">Order No:  <strong>#{{order.external_id | uppercase}}</strong></p></div>-->

<!--    </div>-->
<!--    <div class="botcont">-->
<!--        <div class="feedback-form">-->
<!--            <div class="cont">-->
<!--            <div class="head">Your feedback is important to us!</div>-->
<!--              <div class="stars">-->
<!--&lt;!&ndash;                <ngx-star-rating [(ngModel)]="rating3" [id]="'rating3'"></ngx-star-rating>&ndash;&gt;-->
<!--                <div class="star-rating" (click)="getRating($event, '1')">-->
<!--                    <img *ngIf="rating3 < 1" src="assets/images/grey_1.svg" alt="">-->
<!--                    <img *ngIf="rating3 >=1" src='{{"assets/images/color_"+ rating3 + ".svg"}}' alt="">-->
<!--                </div>-->
<!--                <div class="star-rating" (click)="getRating($event,'2')">-->
<!--                  <img *ngIf="rating3 < 2" src="assets/images/grey_2.svg" alt="">-->
<!--                  <img *ngIf="rating3 >=2" src='{{"assets/images/color_"+ rating3 + ".svg"}}' alt="">-->
<!--                </div>-->
<!--                <div class="star-rating" (click)="getRating($event,'3')">-->
<!--                  <img *ngIf="rating3 < 3" src="assets/images/grey_3.svg" alt="">-->
<!--                  <img *ngIf="rating3 >=3" src='{{"assets/images/color_"+ rating3 + ".svg"}}' alt="">-->
<!--                </div>-->
<!--                <div class="star-rating"  (click)="getRating($event,'4')">-->
<!--                  <img *ngIf="rating3 < 4" src="assets/images/grey_4.svg" alt="">-->
<!--                  <img *ngIf="rating3 >= 4" src='{{"assets/images/color_"+ rating3 + ".svg"}}' alt="">-->
<!--                </div>-->
<!--                <div class="star-rating" (click)="getRating($event,'5')">-->
<!--                  <img *ngIf="rating3 < 5" src="assets/images/grey_5.svg" alt="">-->
<!--                  <img *ngIf="rating3 >= 5" src='{{"assets/images/color_"+ rating3 + ".svg"}}' alt="">-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          <div class="message">{{order.status_name | uppercase}}</div>-->
<!--          <div>-->
<!--            <textarea [(ngModel)]="feedbackorder" class="form-control" rows="2" placeholder="What would you like to share..."></textarea>-->
<!--            <button type="button" class="btn btn-primary w-100" (click)="feedback()">SEND FEEDBACK</button>-->
<!--          </div>-->
<!--        </div>-->
<!--  </div>-->
<!--  </div>-->
<!--</div>-->

<div  *ngIf="order.status_name === 'delivered' || order.status_name === 'reached_store' && !order.order_undeliver">
  <div class="feedback-popup">
    <div class="s-message"><img src="assets/images/successful.gif" alt="">Order No: <strong>#{{order.external_id | uppercase}}</strong></div>
    <div class="botcont">
      <div  style="text-align: center;font-size: 14px;
           padding: 10px; position: relative; font-weight: bold"><p>Your order has been delivered successfully.</p></div>
    </div>
  </div>
</div>
<!-- Feedback Pop Up End Here-->


<div  *ngIf="order.status_name === 'cancelled' && !order.order_undeliver">
    <div class="feedback-popup">
        <div class="s-message"><img src="assets/images/cancel.svg" alt="" style="width: 35%;
    margin: 16% 0% 15% 0%;">
            <div class="txts" style="margin-bottom: 4%">
                <p class="order-id">Order No:  <strong>#{{order.external_id | uppercase}}</strong></p></div>
            <p style="color: red; font-weight: bold;">Your order has been Cancelled.</p></div>
    </div>
</div>

<div *ngIf="order.order_undeliver" style="text-align: center">
    <div class="feedback-popup">
            <div class="txts" style="margin-top: 50%">
                <p class="order-id">Order No:  <strong>#{{order.external_id | uppercase}}</strong></p></div>
            <p style="color: black; font-weight: bold;">Waiting for Rider.</p>
    </div>
</div>

<!-- Order Summary Start Here -->
<section class="order-summary-cont" *ngIf="orderSummaryValue">
  <div class="container">
    <div class="row">
      <div class="col-12 info">Order Info <div (click)="orderSummaryValueClose()" class="close-btn">x</div> </div>
      <div class="col-12">
        <div class="summary">
          <div class="order-number">
            <p class="head"><img src="assets/images/order_number.svg" alt=""> Order Number</p>
            <p class="txt blue">{{order.external_id}}</p>
          </div>
          <div class="address">
            <p class="head"><img src="assets/images/location.svg" alt=""> Delivery Address</p>
            <p class="txt">{{order.delivery_address.google_address}}</p>
          </div>
          <div class="mode">
            <p class="head"><img src="assets/images/payment.svg" alt=""> Payment Mode</p>
            <p class="txt">Paid: <span  class="blue">{{order.payment_type}}</span></p>
          </div>

          <div class="date">
            <p class="head"><img src="assets/images/date_time.svg" alt=""> Date and Time</p>
            <p class="txt">{{order.created_on | date :  'medium'}}</p>
          </div>

          <div class="items-details">
            <table>
              <tbody>
              <tr>
                <td colspan="2"><strong>Items</strong></td>
                <td colspan="2" class="text-right"><strong>Amount</strong></td>
              </tr>
              <tr *ngFor="let item of order.order_items">
                <td colspan="2">{{item.notes}} X{{item?.quantity}}</td>
                <td colspan="2" class="text-right">{{item?.amount | currency : currencyCode}}</td>
              </tr>
              <!--              <tr>-->
              <!--                <td><div class="f-cat non-veg"><span></span></div></td>-->
              <!--                <td>Veg Loaded</td>-->
              <!--                <td class="text-center">x 1</td>-->
              <!--                <td class="text-right">₹199.0</td>-->
              <!--              </tr>-->
<!--              <tr>-->
<!--                <td colspan="2" class="bold">Sub Total</td>-->
<!--                <td colspan="2" class="bold text-right">₹{{order.sub_total}}</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td colspan="2">Discount</td>-->
<!--                <td colspan="2" class="text-right discount">-₹{{(order_Payment?.discount_amount).toFixed(2)}}</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td colspan="2">Taxes &amp; Charges</td>-->
<!--                <td colspan="2" class="text-right">₹{{order_Payment?.tax}}</td>-->
<!--              </tr>-->
              <tr>
                <td colspan="2"><strong>Grand Total</strong></td>
                  <td colspan="2" class="text-right"><strong>{{order.sub_total | currency: currencyCode}}</strong></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Order Summary End Here -->

<!--info button page start here-->
<section class="info-window" *ngIf="infoModelValue">
    <div class="container info-popup">
        <div class="close-btn" (click)="infoModelClose()" style="top:10px; right: 30px;">x</div>
        <div class="row">
            <div class="col-12 topimg">
                <img src="assets/images/info-pic.svg" alt="">
            </div>
        </div>
        <div class="row infotext">
            <div class="col-1">
                <img src="assets/images/tick-g.svg" alt="">
            </div>
            <div class="col-11">
                <p>
                    <strong>Contact-less Delivery</strong>
             On your request for contactless delivery, our delivery executive will leave your order at your doorstep after calling you, once he has reached your location. Please remember to collect the order.</p>
            </div>
            <div class="col-1">
                <img src="assets/images/tick-g.svg" alt="">
            </div>
            <div class="col-11">
                <p>
                    <strong>Daily Body Temperature Check</strong>
                   Our store employees temperature are screened everyday and it is ensured their body temperature is always less than 99.4F/37.4C</p>
            </div>
        </div>

        <div class="row driverdet">
            <div class="col-12">
                <p><strong>{{order.rider.name}}</strong><br>
                    <strong>Vaccinated against Covid-19</strong> <span class="vc">View Certificate</span></p>
                <p class="bt">Body Temperature <span class="tempr">{{body_temp}}&#8457;</span></p>
            </div>
        </div>
    </div>
</section>
<!--info button page end here-->

<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script>
