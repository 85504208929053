import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpBackend, HttpClient} from "@angular/common/http";
import {BehaviorSubject, interval, Subject, Subscription} from 'rxjs';
import {environment} from "../environments/environment";
import {Order, PollingResponse} from './order';

@Injectable({
    providedIn: 'root'
})
export class OrderService implements OnDestroy{

    orderId: string | undefined;
    order: Order = {} as Order;
    rating: any;
    order_status: any;
    orderPayment: any;
    riderPosition = new BehaviorSubject({lat: 0, lng: 0})
    body_temp: any;
    currencyCode: any;
    orderPollingSub: Subscription | undefined;
    pollingResponse: PollingResponse | undefined;

    updatesSubject = new Subject() as Subject<PollingResponse>;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private httpDirect: HttpClient, handler: HttpBackend) {
        this.httpDirect = new HttpClient(handler);
        this.activatedRoute.queryParams.subscribe(params => {
            this.orderId = params['order_id'];
            if (!this.orderId) {
                this.router.navigateByUrl('error').then();
            } else {
                this.orderPollingSub = interval(4000).subscribe(() => {
                    if (!document.hasFocus()) {
                        console.log('Browser tab is changed; skipping polling');
                        return;
                    }
                    this.fetchOrder().then();
                });
                this.fetchOrder().then();
            }
        });

    }

    async fetchOrder() {
        this.pollingResponse = await this.httpDirect.get<PollingResponse>(`${environment.apiUrl}order/order_tracking/${this.orderId}`).toPromise();
        if (this.pollingResponse) {
            this.order = this.pollingResponse.data;
            this.rating = this.pollingResponse.rating;
            this.order_status = this.pollingResponse.order_status;
            this.orderPayment = this.pollingResponse.order_payment[0];
            this.body_temp = this.pollingResponse.rider_avail;
            this.updatesSubject.next(this.pollingResponse);

            if(this.pollingResponse.data.status_name === 'delivered' || this.pollingResponse.data.status_name === 'cancelled'
                ||  this.pollingResponse.data.status_name === 'reached_store' || this.pollingResponse.data.order_undeliver){
                console.log('call unsubscribe after');
                this.ngOnDestroy();
            }
        }
    }


    ngOnDestroy() {
        if (this.orderPollingSub) {
            this.orderPollingSub.unsubscribe();
        }
    }
}


